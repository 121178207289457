export const headerPresets = [
    // {
    //     "id": 1,
    //     "name": 'Header Template 1',
    //     "header": {
    //         "showYears": false,
    //         "scenarios": "Base Scenario",
    //         "programsColumn": [
    //             {
    //                 "header": "Product",
    //                 "field": "Product"
    //             },
    //             {
    //                 "header": "Manufacturer",
    //                 "field": "Manufacturer"
    //             },
    //             {
    //                 "header": "CountryManufacturer",
    //                 "field": "CountryManufacturer"
    //             },
    //             {
    //                 "header": "Programme StartYear",
    //                 "field": "Programme StartYear"
    //             },
    //             {
    //                 "header": "Programme EndYear",
    //                 "field": "Programme EndYear"
    //             },
    //             {
    //                 "header": "Total Value US$",
    //                 "field": "Total Value US$"
    //             },
    //             {
    //                 "header": "Total Quantity",
    //                 "field": "Total Quantity"
    //             },
    //             {
    //                 "header": "Delivery Year",
    //                 "field": "Delivery Year"
    //             },
    //             {
    //                 "header": "Unit Price",
    //                 "field": "Unit Price"
    //             },
    //             {
    //                 "header": "Status",
    //                 "field": "Status"
    //             }
    //         ],
    //         "segmentationsChecked": true

    //     }

    // },

    {
        "id": 2,
        "name": '1 - Standard Multi Edit',
        "header":{
            "showYears": true,
            "scenarios": "Base Scenario",
            "programsColumn": [
                {
                    "header": "Product",
                    "field": "Product"
                },
            ],
            "segmentationsChecked": true
        }
    }

]